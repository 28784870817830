import React from 'react';
import { connect } from "react-redux";
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Seo from '../components/seo';
import Header from '../components/Header';
import Footer from '../components/footer';
import Layout from '../components/layout';
import PgArticle from '../components/BlockLibrary/PetGazette/PgArticle';
import '../sass/main.scss';

class PgArticleTemplate extends React.Component {
     componentDidMount() {
          const brower = typeof window !== `undefined`;
          if (brower) {
               var getOmValue = localStorage.getItem("Petinsurance_OM");
               this.props.setTrackingCode(getOmValue);

               var getPhoneValue = localStorage.getItem("Petinsurance_TrackingPhone");
               this.props.setTrackingPhone(getPhoneValue);
          }
     }

     render() {
          const article = get(this.props, 'data.contentfulPgArticle');
          const path = get(this.props, '*');
          const seoTitleTag = article.seoTitleTag ? article.seoTitleTag : `${article.sidebarTitle} | Pet Insurance Benefits for Employees`;
          const seoMetaDescription = article.seoMetaDescription ? article.seoMetaDescription.seoMetaDescription : article.shortDescription;

          return (
               <Layout>
                    <Seo 
                         pageName={article.omniturePageNameLegacy || article.contentName}
                         specificPhone = {article.specificPhone}
                         trackingCode = {article.trackingCode}
                         title={seoTitleTag}
                         path={path}
                         canonical={article.seoCanonicalUrl ? article.seoCanonicalUrl.seoCanonicalUrl : ''}
                         description={seoMetaDescription}
                         robots={article.robotsDirective ? article.robotsDirective.robotsDirective : ''}
                         image={article.socialImage ? article.socialImage : article.featuredImage}
                         showAntiFlickerSnippet={article.showAntiFlickerSnippet}
                    />
                    <Header header={article.header}></Header>
                    <main id="main-content" role="main">
                         <PgArticle article={article} />
                    </main>
                    <Footer footer={article.footer}></Footer>
               </Layout>
          )
     }
}
const mapDispatchToProps = dispatch => {
     return {
          setTrackingCode: (trackingCode) => dispatch({ type: `TRACKING_CODE`, payload: trackingCode }),
          setTrackingPhone: (trackingPhone) => dispatch({ type: `TRACKING_PHONE`, payload: trackingPhone }),
     };
}

const mapStateToProps = (state) => {
     return {
          state,
     };
}
export default connect(mapStateToProps, mapDispatchToProps)(PgArticleTemplate);

export const pageQuery = graphql`
     query PgArticleBySlug($articleId: String!) {
          site {
               siteMetadata {
                    title
               }
          }
          contentfulPgArticle(id: {eq: $articleId }) {
               ...ContentfulPgArticleFields
          }
     }
`
